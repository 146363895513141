import { fetchAuthSession } from "aws-amplify/auth";
import { useRouter } from "next/router";
import React from "react";
import { useAuthContext } from "../context/AmplifyContext";
import { useStoresContext } from "../context/StoresContext";
import { useStripeSubscription } from "../context/StripeSubscriptionContext";
import { LoadingOverlay } from "../src/components/AppComponents/LoadingOverlay";

function Home() {
  const router = useRouter();
  const { authedUser } = useAuthContext();
  const { stores, isStoresLoading, selectedStore } = useStoresContext();
  const { refetchSubscription } = useStripeSubscription();

  const { sessionID } = router.query;
  if (sessionID) {
    fetch("/api/billings", {
      method: "POST",
      body: JSON.stringify({ sessionID }),
    }).then(refetchSubscription);
  }
  React.useEffect(() => {
    if (!selectedStore && stores.length) return;
    if (router) {
      if (!isStoresLoading && authedUser) {
        if (selectedStore?.isOnboarding) {
          router.push("/onboarding");
        } else if (!stores.length) {
          router.push("/onboarding");
        } else {
          router.push("/dashboard");
        }

        return;
      }

      fetchAuthSession().then((session) => {
        if (!session || !session.userSub) {
          router.push("/login");
        }
      });
    }
  }, [
    isStoresLoading,
    router,
    stores.length,
    authedUser,
    selectedStore,
    selectedStore?.isOnboarding,
  ]);

  return <LoadingOverlay fullPage />;
}

export default Home;
